.Polaris__custom_field_has-value{
    position: absolute;
    right: 160px;
    z-index: 99999999;
    top: 35px;
}

.Polaris__custom_field_has-value input{
    height: 33px;
    padding: 10px;
    border: 1px solid;
    border-color: #c9cccf;
    border-radius: 3px;
}

.page-title{
    padding: 20px 20px 0 20px;
    font-weight: 600;
}

.red{
    color: red;
}
.spinner-center{
    text-align: center;
    padding: 10px 0 30px 0;
}

.show-div{
    display: block;
}

.hide-div{
    display: none;
}